interface Props {
  tag: string;
  key?: number;
  children: any;
  style?: string;
}

export function List({ tag, key, children, style }: Props) {
  let ListTag: any = [];
  tag == "ordered" ? (ListTag = `ol`) : (ListTag = `ul`);
  return (
    <>
      <ListTag
        key={key}
        className={`flex  flex-start cursor-pointer z-30 flex-col lg:flex-row lg:items-center  gap-2   `}
      >
        {children}
      </ListTag>
    </>
  );
}

export function ListRow({ tag, key, children, style }: Props) {
  let ListTag: any = [];
  tag == "ordered" ? (ListTag = `ol`) : (ListTag = `ul`);
  return (
    <ListTag
      key={key}
      className={`flex container mx-auto  xs:items-center ${
        style === "left" ? "justify-center lg:justify-start" : "justify-center "
      } ${
        style === "fixedRow" ? "flex-row" : "flex-col lg:flex-row "
      }  items-center cursor-pointer z-30 xs:gap-2 gap-6 lg:gap-4  `}
    >
      {children}
    </ListTag>
  );
}
